import React from 'react';
import styled from 'styled-components';
import { getCanRingIn, getTeamScore } from '../../../../Helpers/MatchHelper';
import {
  MATCH_STATE_RING_IN_OPEN_LIMITED_TEAMS,
  MATCH_STATE_RING_IN_PAUSED_LIMITED,
} from '../../../../Helpers/MessageHelper';
import { SocketContext } from '../../../../Hooks/SocketContext';
import PlayerItem from '../PlayerItem';

const TeamItem = ({
  className,
  currentPlayerID,
  playerList,
  matchState,
  scoreList = [],
  startingPoints,
  teamsThatDidRingIn = [],
  team,
  index,
  isHost,
  match,
  teamIdWithBonusControl,
}) => {
  const { activePlayerID } = React.useContext(SocketContext);
  const [showAnimation, setShowAnimation] = React.useState(false);
  const animationStyle = showAnimation ? 'team-title-animation' : '';
  const {
    team: {
      id: teamID,
      name: teamName,
      players: { items: teamPlayers = [] } = {},
    },
  } = team || {};
  const teamHasActivePlayerID = !!teamPlayers.find(
    ({ id }) => id === activePlayerID,
  );

  const disabledRingInStates = [
    MATCH_STATE_RING_IN_OPEN_LIMITED_TEAMS,
    MATCH_STATE_RING_IN_PAUSED_LIMITED,
  ];

  const teamColorStyle = index === 0 ? 'team-blue' : 'team-red';
  const active = teamIdWithBonusControl === teamID;

  const cantRingIn =
    !teamHasActivePlayerID &&
    teamsThatDidRingIn.includes(teamID) &&
    disabledRingInStates.includes(matchState);
  const containerClasses = `${className} team-container ${teamColorStyle} ${
    cantRingIn ? 'cant-ring-in' : ''
  } ${active ? 'active' : ''}`;
  const score = getTeamScore({ startingPoints, scoreList, teamID });
  let matchPlayersToRender = playerList.filter(
    (matchPlayer) => matchPlayer.player.teamID === teamID,
  );
  matchPlayersToRender = matchPlayersToRender.sort((a, b) => {
    return a.order - b.order;
  });

  const addAnimation = () => {
    setShowAnimation(true);
    setTimeout(() => setShowAnimation(false), 4500);
  };

  const canRingIn = getCanRingIn({
    activePlayerID,
    matchState,
    teamsThatDidRingIn,
    teamID,
  });

  return (
    <div key={teamID} className={`${containerClasses}`}>
      <div className={`team-title-container`}>
        <div
          onMouseEnter={() => {
            if (teamName.length > 30) addAnimation();
          }}
          onTouchStart={() => {
            if (teamName.length > 16) addAnimation();
          }}
          className={`team-title ${animationStyle}`}
        >
          {teamName}
        </div>
      </div>
      <div className="points-container">{score || 0}</div>
      <div className="player-list">
        {matchPlayersToRender.map((matchPlayer) => (
          <PlayerItem
            key={matchPlayer.id}
            matchPlayer={matchPlayer}
            currentPlayerID={currentPlayerID}
            isHost={isHost}
            match={match}
            matchState={matchState}
            canRingIn={canRingIn}
          />
        ))}
      </div>
    </div>
  );
};

const StyledTeamItem = styled(TeamItem)`
  flex: auto;
  margin: 1rem;
  border: 1px solid black;

  &.team-blue {
    .team-title-container {
      background-color: blue;
    }
    &.active {
      outline: 10px solid blue;
    }
  }

  &.team-red {
    .team-title-container {
      background-color: red;
    }
    &.active {
      outline: 10px solid red;
    }
  }

  .team-title-container {
    font-weight: bold;
    padding: 0.5rem;
    font-size: 0.75rem;
    color: white;
    border: 1px solid black;
    overflow: hidden;
  }

  &.cant-ring-in {
    opacity: 0.1;

    .team-title-container {
      background-color: #333;
    }
  }

  .team-title {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .team-title-animation {
    overflow: visible;
    white-space: nowrap;
    animation-name: team-title;
    animation-duration: 4s;
  }

  @keyframes team-title {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-20rem);
    }
  }

  .points-container {
    font-size: 3rem;
    line-height: 5rem;
    border: 1px solid black;
  }

  .player-list {
    border: 1px solid black;
  }

  .player-list-item {
    line-height: 2rem;
    border-bottom: 1px solid black;
    background-color: #ddd;

    &.is-in-room {
      background-color: #fff;
    }

    &.buzzed-player {
      font-weight: bold;
      background-color: yellow;
    }
  }

  @media (min-width: 1100px) {
    width: 35rem;
    margin-top: 1rem;

    .team-title {
      padding: 0.5rem 1rem;
      font-size: 2rem;
      overflow: hidden;
      white-space: no-wrap;
      text-overflow: ellipsis;
    }

    .team-title-animation {
      overflow: visible;
      white-space: nowrap;
      animation-name: team-title;
      animation-duration: 4s;
    }

    @keyframes team-title {
      from {
        transform: translateX(0);
      }
      to {
        transform: translateX(-20rem);
      }
    }

    .points-container {
      font-size: 5rem;
      line-height: 6rem;
    }

    .player-list-item {
      font-size: 2.5rem;
      line-height: 3rem;
      border-bottom: 1px solid black;
    }
  }
`;

export default StyledTeamItem;
