import { format } from 'date-fns';
import React, { createContext } from 'react';
import {
  createMatch,
  createMatchTemplate,
  editMatch,
  updateMatchTemplate,
} from '../Helpers/Api/Match';
import { DEFAULT_TIMEOUTS } from '../Helpers/MatchHelper';
import { validateMatchTemplate } from '../Helpers/MatchTemplateValidator';
import { validateMatchSettings } from '../Helpers/MatchValidator';
import { get, setIn } from '../Helpers/UtilHelper';
export const MatchSettingsContext = createContext(undefined);
export const { Provider, Consumer: MatchSettingsConsumer } =
  MatchSettingsContext;

const currentDate = format(new Date(), 'yyyy-MM-dd');

const RESET_STATE = 'RESET_STATE';
const SET_ERRORS = 'SET_ERRORS';
const RESET_ERROR = 'RESET_ERROR';
const SET_MATCH_SETTING = 'SET_MATCH_SETTING';

const formatSelectedTeamData = ({ teamNumber, matchPlayers, matchTeams }) => {
  // find the right team based on teamNumber
  let selectedTeam = matchTeams.find((team) => team.order == teamNumber);
  if (!selectedTeam) selectedTeam = get(matchTeams, `${teamNumber - 1}`, {});

  const teamID = get(selectedTeam, `teamID`, '');
  const groupID = get(selectedTeam, `team.groups.items[0].group.id`, 'local');
  const useOwnedTeams = groupID === 'local' || !groupID;
  const players = {};
  let relevantMatchPlayers = matchPlayers.filter(
    (matchPlayer) => get(matchPlayer, 'player.teamID') === teamID,
  );
  relevantMatchPlayers = relevantMatchPlayers.sort((a, b) => {
    return a.order - b.order;
  });
  relevantMatchPlayers.forEach(
    ({ order, playerID, deviceID, id, active }) =>
      (players[order - 1] = { matchPlayerID: id, playerID, deviceID, active }),
  );
  return {
    teamID,
    players,
    groupID,
    useOwnedTeams,
  };
};

const initialState = {
  errors: {},

  // match details
  matchDate: currentDate,
  matchName: '',

  // points
  startingPoints: 0,

  tossUpEnabled: true,
  tossUpPoints: 1,

  showWrongAnswerPenalty: 'no', // 'no', 'custom', or 'question'
  wrongAnswerPenaltyPoints: 5,

  showEarlyCorrectAnswerBonus: 'no', // 'yes' or 'no'
  earlyCorrectAnswerPoints: 5,

  showEarlyWrongAnswerPenalty: 'no', // 'yes' or 'no'
  earlyWrongAnswerPoints: 5,

  teamQuestionEnabled: false,
  teamEndDeliberationEnabled: 'yes', // 'yes' or 'no'

  bonusQuestionEnabled: 'yes', // 'yes' or 'no'
  bonusTotalParts: 1, // int
  bonusPointsPerPart: 1, // int
  bonusBouncebackEnabled: 'yes', // 'yes' or 'no'
  bonusImmediateBouncebackEnabled: 'yes', // 'yes' or 'no'
  bonusQuestionsFollowTossUps: 'ifAnsweredCorrectly', // 'ifSelected', 'ifAnsweredCorrectly'
  bonusControl: 'tossUpWinner', // 'tossUpWinner', 'prompt', 'takeTurns'

  // round timer
  roundTimerEnabled: false,
  roundMinutes: 9,
  roundSeconds: 0,

  // ring-in timer
  ringInTimer: 5,

  // ring-in timer reset
  showResetTimer: 'yes', // 'yes' or 'no'
  resetTimerOption: 'original', // 'original' or 'custom'
  resetTimerDuration: 3,
  preserveMinTimeEnabled: 'no', // 'yes' or 'no'
  preserveMinTimeSeconds: 3, // int

  // tossup answer timer
  tossUpAnswerEnabled: 'no', // 'yes' or 'no'
  tossUpAnswerSeconds: 5,
  soundTossUpAnswerEnabled: 'yes', // 'yes' or 'no'

  // tossup computation timer
  showComputationTime: 'no', // 'yes' or 'no'
  computationTime: 10,

  ringInLimit: 'oncePerTeam', // 'oncePerTeam' or 'oncePerQuestion'

  // team deliberation timer
  teamDeliberationMinutes: 3,
  teamDeliberationSeconds: 0,

  // bonus timers
  bonusAnswerTimerEnabled: 'yes', // 'yes' or 'no'
  bonusAnswerSeconds: 10, // int
  bonusDeliberationTimerEnabled: 'no', // 'yes' or 'no'
  bonusDeliberationSeconds: 10, // int
  bonusComputationTimerEnabled: 'no', // 'yes' or 'no'
  bonusComputationSeconds: 10, // int

  // sounds & display
  soundRoundTimerEnabled: 'yes', // 'yes or 'no'
  soundRingInEnabled: 'host', // 'host', 'player', 'both', or 'none'
  soundRingInTimerEnabled: 'yes', // 'yes or 'no'
  // soundDeliberationTimerEnabled: 'yes', // 'yes or 'no' - DO NOT USE THIS
  soundTeamDeliberationTimerEnabled: 'yes', // 'yes' or 'no'
  soundBonusAnswerTimerEnabled: 'yes', // 'yes' or 'no'
  soundBonusDeliberationTimerEnabled: 'yes', // 'yes or 'no'
  soundTimeoutTimerEnabled: 'yes', // 'yes or no'
  playerTimersEnabled: 'yes', // 'yes' or 'no'

  // timeouts
  timeoutsEnabled: 'yes', // 'yes' or 'no' (or null for old records)
  timeouts: DEFAULT_TIMEOUTS,

  // match template options
  templateID: '',
  templateName: '',
  saveTemplate: 'no', // 'yes' or 'no'
  templateType: 'private', // 'private' or 'public'
  templateDescription: '',
};

const reducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case RESET_STATE: {
      return initialState;
    }
    case SET_ERRORS: {
      const { errors } = state;
      const { errors: newErrors } = payload;
      return {
        ...state,
        errors: { ...errors, ...newErrors },
      };
    }
    case RESET_ERROR: {
      const { key } = payload;
      const { errors } = state;
      const { [key]: omit, ...remainingErrors } = errors;
      return {
        ...state,
        errors: remainingErrors,
      };
    }
    case SET_MATCH_SETTING: {
      return {
        ...state,
        ...payload,
      };
    }
    default: {
      console.log(`Match settings reducer type ${type} is not supported`);
      return state;
    }
  }
};

function newTeam() {
  return {
    teamID: '',
    players: {},
    groupID: 'local',
    useOwnedTeams: true,
  };
}

const defaultSelectedTeamState = [newTeam(), newTeam()];

function replaceAt(arr, index, item) {
  let newArr = [...arr];
  newArr[index] = item;
  return newArr;
}

export const MatchSettingsProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const [playerCount, setPlayerCount] = React.useState(4);
  const [selectedTeams, setSelectedTeams] = React.useState(
    defaultSelectedTeamState,
  );

  const setMatchSetting = ({ payload = {}, errorsToClear = [] }) => {
    dispatch({
      type: SET_MATCH_SETTING,
      payload,
    });
    errorsToClear.forEach((key) => clearError(key));
  };

  const setNumberOfTeams = (numberOfTeams) => {
    const diff = numberOfTeams - selectedTeams.length;
    if (numberOfTeams < 2 || numberOfTeams > 6 || diff === 0) {
      return;
    }
    if (diff > 0) {
      if (selectedTeams.length === 2) {
        setMatchSetting({
          payload: {
            bonusBouncebackEnabled: 'no',
            bonusImmediateBouncebackEnabled: 'no',
          },
          errorsToClear: [
            'bonusBouncebackEnabled',
            'bonusImmediateBouncebackEnabled',
          ],
        });
      }
      const newTeams = Array.from(Array(diff)).map(() => newTeam());
      setSelectedTeams((prev) => [...prev, ...newTeams]);
    } else {
      if (
        window.confirm(
          'You are going to a lower team count. Some data may be lost. Continue?',
        )
      ) {
        setSelectedTeams((prev) => prev.slice(0, prev.length + diff));
      }
    }
  };

  const setSelectedTeamId = ({ teamNumber, teamID }) => {
    setSelectedTeams((prev) =>
      replaceAt(prev, teamNumber, {
        ...prev[teamNumber],
        teamID,
        players: {},
      }),
    );
  };

  const setSelectedGroupId = ({ teamNumber, groupID }) => {
    const useOwnedTeams = groupID === 'local';
    setSelectedTeams((prev) =>
      replaceAt(prev, teamNumber, {
        ...prev[teamNumber],
        groupID,
        teamID: '',
        players: {},
        useOwnedTeams,
      }),
    );
  };

  const setSelectedTeamPlayers = ({ teamNumber, index, playerID }) => {
    setSelectedTeams((prev) =>
      setIn(prev, `${teamNumber}.players.${index}.playerID`, playerID),
    );
  };

  const setSelectedTeamDevices = ({ teamNumber, index, deviceID }) => {
    setSelectedTeams((prev) =>
      setIn(prev, `${teamNumber}.players.${index}.deviceID`, deviceID),
    );
  };

  const clearError = (key) => {
    dispatch({
      type: RESET_ERROR,
      payload: {
        key,
      },
    });
  };

  const initMatchSettings = ({ matchPlayers, matchTeams, settings }) => {
    setMatchSetting({ payload: settings || {} });
    setPlayerCount(matchPlayers.length / matchTeams.length);
    const newSelectedTeams = matchTeams.map((matchTeam, index) =>
      formatSelectedTeamData({
        teamNumber: index + 1,
        matchPlayers,
        matchTeams,
      }),
    );
    setSelectedTeams(newSelectedTeams);
  };

  const handleResetState = () => {
    dispatch({
      type: RESET_STATE,
    });
    setPlayerCount(4);
    setSelectedTeams(defaultSelectedTeamState);
  };

  const setErrors = (errors = {}) => {
    dispatch({
      type: SET_ERRORS,
      payload: {
        errors,
      },
    });
  };

  const validateAndCreateMatch = async () => {
    const newErrors = validateMatchSettings({
      ...state,
      playerCount,
      selectedTeams,
    });
    const hasErrors = Object.keys(newErrors).length !== 0;
    if (hasErrors) {
      setErrors(newErrors);
      return { hasErrors };
    }
    // Create the match and return the result
    const match = await createMatch({ settings: state, selectedTeams });
    return { match };
  };

  const validateAndEditMatch = async ({ matchID, matchStatus }) => {
    const newErrors = validateMatchSettings({
      ...state,
      playerCount,
      selectedTeams,
    });
    const hasErrors = Object.keys(newErrors).length !== 0;
    if (hasErrors) {
      setErrors(newErrors);
      return { hasErrors };
    }
    // Edit the match and return the result
    const { matchDate, matchName } = state;
    return editMatch({
      matchID,
      matchDate,
      matchName,
      matchStatus,
      settings: state,
      selectedTeams,
    });
  };

  const validateAndCreateMatchTemplate = async () => {
    const newErrors = validateMatchTemplate({
      ...state,
    });
    const hasErrors = Object.keys(newErrors).length !== 0;
    if (hasErrors) {
      setErrors(newErrors);
      return { hasErrors };
    }

    // Create the match template and return the result
    const matchTemplate = await createMatchTemplate({ settings: state });
    return { matchTemplate };
  };

  const validateAndEditMatchTemplate = async ({ id, templateUser }) => {
    const newErrors = validateMatchTemplate({
      ...state,
    });
    const hasErrors = Object.keys(newErrors).length !== 0;
    if (hasErrors) {
      setErrors(newErrors);
      return { hasErrors };
    }
    // Edit the match and return the result
    const matchTemplate = await updateMatchTemplate({
      id,
      user: templateUser,
      propsToUpdate: { ...state },
    });
    return { matchTemplate };
  };

  return (
    <Provider
      value={{
        ...state,
        clearError,
        handleResetState,
        initMatchSettings,
        setMatchSetting,
        validateAndCreateMatch,
        validateAndCreateMatchTemplate,
        validateAndEditMatch,
        validateAndEditMatchTemplate,
        selectedTeams,
        setNumberOfTeams,
        setSelectedGroupId,
        setSelectedTeamId,
        setSelectedTeamPlayers,
        setSelectedTeamDevices,
        playerCount,
        setPlayerCount,
      }}
    >
      {children && children}
    </Provider>
  );
};

export function useMatchSettings() {
  let context = React.useContext(MatchSettingsContext);

  if (context === undefined) {
    throw new Error(
      'useMatchSettings must be used from within a MatchSettingsProvider',
    );
  }

  return context;
}
