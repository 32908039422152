import React from 'react';
import styled from 'styled-components';
import { getOrderedTeamList } from '../../Helpers/MatchHelper';
import TeamItem from './Components/TeamItem';

const Scoreboard = ({
  className,
  currentPlayerID,
  teamIdWithBonusControl = null,
  match,
  matchState,
  scoreList = [],
  startingPoints,
  teamsThatDidRingIn = [],
  isHost,
}) => {
  const {
    id: matchID,
    players: { items: playerList = [] } = {},
    teams: { items: teamList = [] } = {},
  } = match || {};
  if (!matchID) return null;

  const orderedTeamList = getOrderedTeamList(teamList);
  const numberOfTeams = teamList.length;

  return (
    <div
      className={`scoreboard ${
        numberOfTeams > 2 ? 'more-than-two-teams' : ''
      } ${className}`}
    >
      {orderedTeamList.map((team, index) => (
        <TeamItem
          currentPlayerID={currentPlayerID}
          playerList={playerList}
          matchState={matchState}
          scoreList={scoreList}
          startingPoints={startingPoints}
          teamsThatDidRingIn={teamsThatDidRingIn}
          team={team}
          index={index}
          key={team.id}
          isHost={isHost}
          match={match}
          teamIdWithBonusControl={teamIdWithBonusControl}
        />
      ))}
    </div>
  );
};

const StyledScoreboard = styled(Scoreboard)`
  display: flex;
  justify-content: center;
`;

export default StyledScoreboard;
