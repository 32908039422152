import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useCookies } from 'react-cookie';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import HeaderAuthed from '../../Components/HeaderAuthed';
import LoaderCentered from '../../Components/LoaderCentered';
import Scoreboard from '../../Components/Scoreboard';
import Timer, {
  ROUND_TIMER_COOKIE,
  TIMER_TYPE_BONUS_ANSWER,
  TIMER_TYPE_BONUS_DELIBERATION,
  TIMER_TYPE_RING_IN,
  TIMER_TYPE_ROUND,
  TIMER_TYPE_TEAM_DELIBERATION,
  TIMER_TYPE_TIMEOUT,
  TIMER_TYPE_TOSS_UP_ANSWER,
} from '../../Components/Timer';
import {
  fetchMatch,
  MATCH_COMPLETED,
  subscribeToMatchPlayerUpdates,
  updateMatch,
  updateMatchPlayer,
  updateMatchPlayersInRoom,
} from '../../Helpers/Api/Match';
import {
  createScore,
  subscribeToDeletedScores,
  subscribeToEditedScores,
  subscribeToNewScores,
} from '../../Helpers/Api/Score';
import {
  BONUS_QUESTION,
  getTimerExpired,
  getTimerSecondsLeft,
  setDefaultQuestionType,
  TEAM_QUESTION,
  TOSS_UP_QUESTION,
} from '../../Helpers/HostRoomHelper';
import {
  getCanRingIn,
  getOtherPlayersString,
  getQuestionNumberFromScores,
  getQuestionTypeText,
  hasTypeBeenAnswered,
} from '../../Helpers/MatchHelper';
import {
  MATCH_STATE_CHOOSE_QUESTION,
  MATCH_STATE_RING_IN_TEST_CLOSED,
  MATCH_STATE_RING_IN_TEST_OPEN,
  MATCH_STATE_TIMEOUT_OPEN,
  MESSAGE_TYPE_CHECK_HOST,
  MESSAGE_TYPE_CONFIRM_HOST,
  MESSAGE_TYPE_END_QUESTION,
  MESSAGE_TYPE_END_TIMEOUT,
  MESSAGE_TYPE_JOIN_ROOM,
  MESSAGE_TYPE_LEAVE_ROOM,
  MESSAGE_TYPE_PLAYER_DISCONNECT,
  MESSAGE_TYPE_RESET,
  MESSAGE_TYPE_RING_IN,
  MESSAGE_TYPE_RING_IN_TEST,
  MESSAGE_TYPE_ROUND_TIMER_ENDED,
  MESSAGE_TYPE_SET_CURRENT_QUESTION,
  MESSAGE_TYPE_SET_FIRST_RING_IN,
  MESSAGE_TYPE_SET_FIRST_RING_IN_TEST,
  MESSAGE_TYPE_SET_STATE,
  MESSAGE_TYPE_SHARE_CLIENT_LIST,
  MESSAGE_TYPE_SYNC_HOST_STATE,
  MESSAGE_TYPE_SYNC_PLAYER_STATE,
  MESSAGE_TYPE_TEAM_END_DELIBERATION,
  TIMER_STATE_PAUSED,
  TIMER_STATE_VISIBLE,
} from '../../Helpers/MessageHelper';
import { checkTruthy, get } from '../../Helpers/UtilHelper';
import { SocketContext } from '../../Hooks/SocketContext';
import { TimerContext } from '../../Hooks/TimerContext';
import useHostLoader from '../../Hooks/useHostLoader';
import MatchControls from './Components/MatchControls';
const roundTimeUpSound = new Audio('/sounds/RoundTimeUp.wav');
const ringInTimeUpSound = new Audio('/sounds/RingInTimeUp.wav');
const deliberationTimeUpSound = new Audio('/sounds/DeliberationTimeUp.wav');
const answerTimeUpSound = new Audio('/sounds/AnswerTimeUp.wav');
const alertSound = new Audio('/sounds/AlertSound.wav');

const HostRoom = (props) => {
  const { className, match: { params: { matchID } = {} } = {} } = props;
  const { currentUser: hostUserData, loadingComplete } = useHostLoader({
    requiresAuth: true,
    requiresSubscription: true,
  });
  const [cookies] = useCookies();
  const history = useHistory();
  const location = useLocation();
  const { isNewHost } = location.state || {};
  const [match, setMatch] = useState(null);
  const [ringIns, setRingIns] = useState([]);
  const [didJoinRoom, setDidJoinRoom] = useState(false);
  const [scoreList, setScoreList] = useState([]);
  const [teamDeliberationDuration, setTeamDeliberationDuration] =
    useState(null);
  const [endMatch, setEndMatch] = useState(false);
  const [showModalError, setShowModalError] = useState(false);
  const [playerRangIn, setPlayerRangIn] = useState(false);
  const [isReplayingQuestion, setIsReplayingQuestion] = useState(false);
  const [questionNumberInputDisplay, setQuestionNumberInputDisplay] =
    useState(null);
  const [replayQuestionNumber, setReplayQuestionNumber] = useState(null);
  const [nextQuestionNumberToPlay, setNextQuestionNumberToPlay] =
    useState(null);
  const [roundTimerExpired, setRoundTimerExpired] = useState(false);
  const timerContext = useContext(TimerContext);

  const {
    stateTimerRingIn,
    stateTimerTeamDeliberation,
    stateTimerBonusDeliberation,
    stateTimerBonusAnswer,
    resetTimerState,
    // stateTimerTossUpAnswer <- If we need to share timer state with players, enable this
  } = timerContext;

  const socketContext = useContext(SocketContext);
  const {
    activeQuestionNumber,
    bonusTeamControlID,
    bonusDeliberationTimerState,
    bonusAnswerTimerState,
    activePlayerID,
    clients,
    currentBonusPart,
    currentQuestion,
    dispatch,
    initMessageListener,
    joinRoom,
    leaveRoom,
    matchState,
    messageListenerActive,
    questionType,
    removeMessageListener,
    ringInTimerState,
    roundTimerState,
    sendMessage,
    showPointButtons,
    socket,
    teamDeliberationTimerState,
    teamsThatDidRingIn,
    useComputationTime,
    useBonusComputationTime,
    bonusDeliberationComplete,
    bouncebackTeamEnabled,
    persistBonusComputationTimerHidden,
    persistBonusDeliberationTimerVisible,
    bonusAnswerTimerComplete,
    bonusIncorrectParts,
    timeoutTimerState,
    tossUpAnswerTimerState,
    timeoutDuration,
  } = socketContext;

  // messageHelper state setter that mimics useState - accepts a payload object
  // if any value of the payload object is a function, parse it similar to useState's setter
  const setState = useCallback(
    (payload = {}) => {
      const newPayload = {};
      const keys = Object.keys(payload);
      keys.forEach((key) => {
        let value = payload[key];
        if (typeof payload[key] === 'function') {
          const currentValue = socketContext[key];
          value = payload[key](currentValue);
        }
        newPayload[key] = value;
      });
      sendMessage({
        matchID,
        type: MESSAGE_TYPE_SET_STATE,
        payload: newPayload,
      });
    },
    [matchID, socketContext],
  );

  const setQuestionType = (value) => {
    setState({ questionType: value });
  };

  const setUseComputationTime = (value) => {
    setState({ useComputationTime: value });
  };

  const {
    id: fetchedMatchID,
    matchDate,
    matchName,
    matchStatus: currentMatchStatus,
    players: { items: playerList = [] } = {},
    scores: { items: scoreItems } = {},
    user: matchUser,
  } = match || {};

  const settings = get(match, 'settings.items.0', {});
  const {
    computationTime,
    resetTimerDuration,
    resetTimerOption,
    ringInTimer,
    roundTimerEnabled,
    roundMinutes,
    roundSeconds,
    startingPoints,
    soundRingInEnabled,
    soundRingInTimerEnabled,
    soundRoundTimerEnabled,
    teamDeliberationMinutes,
    teamDeliberationSeconds,
    teamQuestionEnabled,
    tossUpEnabled,
    bonusQuestionEnabled,
    bonusAnswerTimerEnabled,
    bonusAnswerSeconds,
    soundBonusAnswerTimerEnabled,
    soundBonusDeliberationTimerEnabled,
    soundTeamDeliberationTimerEnabled,
    bonusDeliberationTimerEnabled,
    bonusDeliberationSeconds,
    bonusComputationSeconds,
    tossUpAnswerEnabled,
    tossUpAnswerSeconds,
    soundTossUpAnswerEnabled,
    soundTimeoutTimerEnabled,
    preserveMinTimeEnabled,
    preserveMinTimeSeconds,
  } = settings;

  let questionText = useMemo(() => {
    return getQuestionTypeText({
      activeQuestionNumber,
      bonusPart: currentBonusPart,
      matchState,
    });
  }, [matchState, currentBonusPart, activeQuestionNumber]);

  const roundTimerDurationSec = roundMinutes * 60 + roundSeconds;
  const storedRoundTimerDurationSec = React.useMemo(() => {
    const { [ROUND_TIMER_COOKIE]: roundTimerCookie } = cookies || {};
    if (roundTimerCookie && matchID === roundTimerCookie.matchID) {
      return roundTimerCookie.secondsLeft;
    }
    return null;
  }, []);
  const ringInTimerDuration =
    useComputationTime && computationTime ? computationTime : ringInTimer;

  const ringInTimerResetDurationSec =
    resetTimerOption === 'custom' && resetTimerDuration
      ? resetTimerDuration
      : ringInTimerDuration;
  const bonusAnswerTimerDuration =
    useBonusComputationTime && bonusComputationSeconds
      ? bonusComputationSeconds
      : bonusAnswerSeconds;

  //TODO: Save team deliberation timer with a cookie?

  // Check if duplicate players are already in the match
  useEffect(() => {
    const clientsArr = Object.values(clients);
    for (let i = 0; i < clientsArr.length; i++) {
      for (let j = 0; j < clientsArr.length; j++) {
        if (i !== j) {
          if (clientsArr[i].playerID === clientsArr[j].playerID) {
            sendMessage({
              matchID: matchID,
              type: MESSAGE_TYPE_LEAVE_ROOM,
              payload: {
                playerID: clientsArr[i].playerID,
                isDuplicatePlayer: 1,
              },
            });
          }
        }
      }
    }
  }, [clients]);

  useEffect(() => {
    setTeamDeliberationDuration(
      teamDeliberationMinutes * 60 + teamDeliberationSeconds,
    );
  }, [teamDeliberationMinutes, teamDeliberationSeconds]);

  useEffect(() => {
    // fetch match
    const init = async () => {
      dispatch({ type: MESSAGE_TYPE_RESET });
      resetTimerState();
      const matchResult = await fetchMatch({ matchID });
      const matchOwner = get(matchResult, 'user');
      const hostID = get(hostUserData, 'attributes.sub');

      // prevent host users from hosting games they don't own
      if (hostID && hostID !== matchOwner) history.push('/host');
      // save match to state
      if (matchResult) setMatch(matchResult);
    };
    if (loadingComplete && hostUserData && matchID) init();
  }, [hostUserData, loadingComplete, matchID]);

  useEffect(() => {
    // set initial questionType after the match has been fetched and the messageListener has been initiated
    if (match && messageListenerActive && !questionType) {
      setQuestionType(setDefaultQuestionType(match));
    }
  }, [match, messageListenerActive, questionType]);

  const [isConnected, setIsConnected] = useState(false);

  useEffect(() => {
    // after the match is fetched, join room and start listening to messages
    const join = () => {
      setIsConnected(true);
      joinRoom({ matchID, isHost: true, isNewHost });
    };
    socket.on('reconnect', join);
    const onDisconnect = () => setIsConnected(false);
    socket.on('disconnect', onDisconnect);

    if (!didJoinRoom && match) {
      console.log('Joining room:', matchID);
      join();
      initMessageListener(messageHandler);
      sendMessage({
        matchID,
        type: MESSAGE_TYPE_SYNC_HOST_STATE,
        payload: {
          settings,
          matchState,
          currentBonusPart,
          bonusTeamControlID,
          teamsThatDidRingIn,
          activePlayerID,
          showPointButtons,
          questionType,
          ringInTimerState,
          bonusDeliberationTimerState,
          bonusAnswerTimerState,
          useComputationTime,
          useBonusComputationTime,
          bonusDeliberationComplete,
          bouncebackTeamEnabled,
          persistBonusComputationTimerHidden,
          persistBonusDeliberationTimerVisible,
          bonusAnswerTimerComplete,
          bonusIncorrectParts,
          teamDeliberationTimerState,
          teamDeliberationTimeLeft: teamDeliberationDuration,
        },
      });
      setDidJoinRoom(true);
    } else if (didJoinRoom && match) {
      initMessageListener(messageHandler);
    }
    return () => {
      removeMessageListener(messageHandler);
      socket.off('reconnect', join);
      socket.off('disconnect', onDisconnect);
    };
  }, [didJoinRoom, match]);

  // HANDLE SYNC PLAYER STATE
  useEffect(() => {
    sendMessage({
      matchID,
      type: MESSAGE_TYPE_SYNC_PLAYER_STATE,
      payload: {
        matchState,
        activeQuestionNumber,
        currentBonusPart,
        bonusTeamControlID,
        teamsThatDidRingIn,
        activePlayerID,
        showPointButtons,
        questionType,
        ringInTimerState,
        bonusDeliberationTimerState,
        bonusAnswerTimerState,
        useComputationTime,
        useBonusComputationTime,
        bonusDeliberationComplete,
        bouncebackTeamEnabled,
        persistBonusComputationTimerHidden,
        persistBonusDeliberationTimerVisible,
        bonusAnswerTimerComplete,
        bonusIncorrectParts,
        ringInTimerSecondsLeft: getTimerSecondsLeft(stateTimerRingIn),
        bonusAnswerTimerSecondsLeft: getTimerSecondsLeft(stateTimerBonusAnswer),
        bonusDeliberationTimerSecondsLeft: getTimerSecondsLeft(
          stateTimerBonusDeliberation,
        ),
        bonusAnswerTimerExpired: getTimerExpired(stateTimerBonusAnswer),
        bonusDeliberationTimerExpired: getTimerExpired(
          stateTimerBonusDeliberation,
        ),
        teamDeliberationTimerState,
        teamDeliberationTimeLeft: getTimerSecondsLeft(
          stateTimerTeamDeliberation,
        ),
        teamDeliberationTimerExpired: getTimerExpired(
          stateTimerTeamDeliberation,
        ),
      },
    });
  }, [clients]);

  const messageHandler = async (messageData) => {
    // handle incoming messages
    console.log('Received message:', messageData);
    const {
      type,
      payload: { skipAPIUpdate, playerID, socketID, isHost, isNewHost } = {},
    } = messageData || {};

    // HANDLE RING IN MESSAGE
    if (
      playerID &&
      (type === MESSAGE_TYPE_RING_IN || type === MESSAGE_TYPE_RING_IN_TEST)
    ) {
      setRingIns((prev) => [...prev, playerID]);
    }

    // HANDLE DUPLICATE HOST - host 1 sends message to kick host 2
    if (isHost && !isNewHost && type === MESSAGE_TYPE_JOIN_ROOM) {
      sendMessage({
        matchID: matchID,
        type: MESSAGE_TYPE_LEAVE_ROOM,
        payload: { socketID, isHost },
      });
    }

    // HANDLE DUPLICATE HOST - host 2 receives kick message from host 1 and leaves match
    if (isHost && type === MESSAGE_TYPE_LEAVE_ROOM) {
      if (socket.id === socketID) {
        history.push('/host', { matchID });
      }
    }

    // HANDLE NEW HOST - host 1 receives kick messages from host 2 and leaves match
    if (isHost && isNewHost && type === MESSAGE_TYPE_JOIN_ROOM) {
      sendMessage({
        matchID,
        type: MESSAGE_TYPE_SHARE_CLIENT_LIST,
        payload: { clients },
      });
      leaveRoom({ matchID, isHost: true, skipMessage: true });
      history.push('/host', { kickedForNewHost: true });
    }

    // HANDLE PLAYER JOIN ROOM FROM SOCKET
    if (playerID && type === MESSAGE_TYPE_JOIN_ROOM) {
      const isDuplicatePlayer = findDuplicatePlayers(playerID);
      if (isDuplicatePlayer) {
        sendMessage({
          matchID: matchID,
          type: MESSAGE_TYPE_LEAVE_ROOM,
          payload: { playerID, isDuplicatePlayer },
        });
        return;
      } else {
        await updateMatchPlayerWhenJoinOrLeave({ playerID, active: 1 });
      }
    }

    // HANDLE PLAYER LEAVE ROOM FROM SOCKET
    if (playerID && type === MESSAGE_TYPE_LEAVE_ROOM && !skipAPIUpdate) {
      await updateMatchPlayerWhenJoinOrLeave({ playerID, active: 0 });
    }

    if (socketID && type === MESSAGE_TYPE_PLAYER_DISCONNECT) {
      const playerID = get(clients, `${socketID}.playerID`);
      if (playerID) {
        await updateMatchPlayerWhenJoinOrLeave({ playerID, active: 0 });
      } else {
        return;
      }
    }

    // HANDLE PREVENT DUPLICATE STATE UPDATE WHEN PLAYER JOINS
    if (type === MESSAGE_TYPE_SYNC_PLAYER_STATE) {
      return;
    }

    // HANDLE PREVENT DUPLICATE STATE UPDATE WHEN HOST JOINS
    if (type === MESSAGE_TYPE_SYNC_HOST_STATE) {
      return;
    }

    if (type === MESSAGE_TYPE_CHECK_HOST) {
      sendMessage({ matchID, type: MESSAGE_TYPE_CONFIRM_HOST });
      return;
    }

    // dispatch message to state reducer
    dispatch(messageData);
  };

  const calculateQuestionNumber = () => {
    if (!currentQuestion || !questionType) return;
    if (scoreList.length === 0) {
      return 1;
    }
    const currentQuestionTypeHasBeenAnswered = hasTypeBeenAnswered({
      currentQuestion,
      questionType,
      scoreList,
    });

    const newNumber = currentQuestionTypeHasBeenAnswered
      ? currentQuestion + 1
      : currentQuestion;
    return newNumber;
  };

  useEffect(() => {
    if (matchState === MATCH_STATE_CHOOSE_QUESTION && !isReplayingQuestion) {
      const activeQuestionNumber = calculateQuestionNumber();
      setState({ activeQuestionNumber });
      setQuestionNumberInputDisplay(activeQuestionNumber);
    }
  }, [matchState, questionType, scoreList]);

  useEffect(() => {
    if (!isReplayingQuestion) {
      setQuestionNumberInputDisplay(activeQuestionNumber);
    }
  }, [activeQuestionNumber]);

  const findDuplicatePlayers = (playerID) => {
    return Object.values(clients).some((c) => c.playerID === playerID);
  };

  const updateMatchPlayerWhenJoinOrLeave = async ({ playerID, active }) => {
    const matchPlayer = playerList.find((p) => p.playerID === playerID);
    const matchPlayerID = get(matchPlayer, 'id');
    if (matchPlayerID) {
      const updateMatchPlayerInput = {
        id: matchPlayerID,
        active,
        matchID,
        playerID,
      };
      await updateMatchPlayer({ input: updateMatchPlayerInput });
    }
  };

  useEffect(() => {
    // set first player when new ringIn is received
    const firstPlayerID = ringIns[0];
    const matchPlayer = playerList.find((p) => p.playerID === firstPlayerID);
    const firstPlayerTeamID = get(matchPlayer, 'player.teamID');
    if (firstPlayerID && getCanRingIn({ matchState })) {
      const messageType =
        matchState === MATCH_STATE_RING_IN_TEST_OPEN
          ? MESSAGE_TYPE_SET_FIRST_RING_IN_TEST
          : MESSAGE_TYPE_SET_FIRST_RING_IN;
      sendMessage({
        matchID,
        type: messageType,
        payload: { playerID: firstPlayerID, teamID: firstPlayerTeamID },
      });
      setPlayerRangIn(true);
      if (
        soundRingInEnabled === 'host' ||
        soundRingInEnabled === 'both' ||
        soundRingInEnabled === 'yes' // used for backwards compatibility of this match setting
      )
        alertSound.play();
      handleShowTossUpAnswerTimer();
    }
  }, [ringIns]);

  const [scoreSubscriber, setScoreSubscriber] = useState(null);
  const [scoreDeleteSubscriber, setScoreDeleteSubscriber] = useState(null);
  const [matchPlayerSubscriber, setMatchPlayerSubscriber] = useState(null);
  const [scoreEditSubscriber, setScoreEditSubscriber] = useState(null);
  useEffect(() => {
    const init = async () => {
      // save initial scores to state
      setScoreList(scoreItems);
      // get question number from scores
      const questionNumberFromScores = getQuestionNumberFromScores(scoreItems);
      setNextQuestionNumberToPlay(questionNumberFromScores);
      dispatch({
        type: MESSAGE_TYPE_SET_CURRENT_QUESTION,
        payload: { currentQuestion: questionNumberFromScores },
      });

      // subscribe to score creates to receive new scores
      if (scoreSubscriber) scoreSubscriber.unsubscribe();
      const newScoreSubscriber = await subscribeToNewScores({
        handleUpdate: handleNewScore,
        matchID,
      });
      setScoreSubscriber(newScoreSubscriber);

      // subscribe to score deletes to receive new scores
      if (scoreDeleteSubscriber) scoreDeleteSubscriber.unsubscribe();
      const newScoreDeleteSubscriber = await subscribeToDeletedScores({
        handleUpdate: handleDeleteScore,
        matchID,
      });
      setScoreDeleteSubscriber(newScoreDeleteSubscriber);

      // subscribe to score edits to receive updated scores
      if (scoreEditSubscriber) scoreEditSubscriber.unsubscribe();
      const newScoreEditSubscriber = await subscribeToEditedScores({
        handleUpdate: handleEditScore,
        matchID,
      });
      setScoreEditSubscriber(newScoreEditSubscriber);

      // subscribe to matchPlayer updates to see who is in the match
      if (matchPlayerSubscriber) matchPlayerSubscriber.unsubscribe();
      const newPlayerSubscriber = await subscribeToMatchPlayerUpdates({
        handleUpdate: handleMatchPlayerUpdate,
        matchID,
        user: matchUser,
      });
      setMatchPlayerSubscriber(newPlayerSubscriber);
    };
    if (fetchedMatchID) init();

    return () => {
      if (scoreSubscriber) scoreSubscriber.unsubscribe();
      if (scoreDeleteSubscriber) scoreDeleteSubscriber.unsubscribe();
      if (scoreEditSubscriber) scoreEditSubscriber.unsubscribe();
      if (matchPlayerSubscriber) matchPlayerSubscriber.unsubscribe();
    };
  }, [fetchedMatchID]);

  const handleMatchPlayerUpdate = (data) => {
    // update which players are in the match
    const {
      value: {
        data: { onUpdateMatchPlayerPublic: updatedMatchPlayer = {} } = {},
      } = {},
    } = data || {};
    if (updatedMatchPlayer.matchID !== matchID) return;
    updateMatchPlayersInRoom({ updatedMatchPlayer, setMatchCb: setMatch });
  };

  const handleNewScore = (data) => {
    // save new score to state
    const {
      value: { data: { onCreateScorePublic: newScore = {} } = {} },
    } = data || {};
    setScoreList((prev) => [...prev, newScore]);
  };

  const handleDeleteScore = (data) => {
    const {
      value: { data: { onDeleteScorePublic: deletedScore = {} } = {} },
    } = data || {};
    // save deleted score to state
    setScoreList((prev) => {
      return prev.filter((score) => {
        return score.id !== deletedScore?.id;
      });
    });
  };

  const handleEditScore = (data) => {
    const {
      value: { data: { onUpdateScorePublic: updatedScore = {} } = {} },
    } = data || {};
    // save edited score to state
    setScoreList((prev) => {
      return prev.map((score) => {
        if (updatedScore.id === score.id) {
          return updatedScore;
        }
        return score;
      });
    });
  };

  const handleShowTossUpAnswerTimer = () => {
    setState({
      tossUpAnswerTimerState: TIMER_STATE_VISIBLE,
    });
  };

  const endQuestion = ({
    shouldEndReplayQuestion = false,
    canIncrementQuestionNumber = true,
    pauseRoundTimer = false,
  } = {}) => {
    // gather list of enabled question types, excluding your current question type
    const enabledQuestionTypes = [];
    if (tossUpEnabled && questionType !== TOSS_UP_QUESTION) {
      enabledQuestionTypes.push(TOSS_UP_QUESTION);
    }
    if (checkTruthy(bonusQuestionEnabled) && questionType !== BONUS_QUESTION) {
      enabledQuestionTypes.push(BONUS_QUESTION);
    }
    if (teamQuestionEnabled && questionType !== TEAM_QUESTION) {
      enabledQuestionTypes.push(TEAM_QUESTION);
    }
    // filter out question types that have not been answered
    const questionTypesThatHaveBeenAnswered = enabledQuestionTypes.filter(
      (type) => {
        return hasTypeBeenAnswered({
          currentQuestion,
          questionType: type,
          scoreList,
        });
      },
    );
    // compare the two arrays to see if all questions have been answered
    const allTypesHaveBeenAnswered =
      enabledQuestionTypes.length === questionTypesThatHaveBeenAnswered.length;

    // increment question number if all types have been answered or active question number is greater than current question (i.e. you answered the same type twice before answering all types for the current question)
    const incrementCurrentQuestion =
      (canIncrementQuestionNumber &&
        allTypesHaveBeenAnswered &&
        !isReplayingQuestion) ||
      activeQuestionNumber > currentQuestion;

    if (isReplayingQuestion && shouldEndReplayQuestion) {
      setState({
        currentQuestion: nextQuestionNumberToPlay,
        activeQuestionNumber: nextQuestionNumberToPlay,
      });
      setQuestionNumberInputDisplay(nextQuestionNumberToPlay);
      setIsReplayingQuestion(false);
      setNextQuestionNumberToPlay(null);
      setReplayQuestionNumber(null);
    }

    sendMessage({
      matchID,
      type: MESSAGE_TYPE_END_QUESTION,
      payload: {
        incrementQuestionNumber: incrementCurrentQuestion,
        pauseRoundTimer,
      },
    });
    setRingIns([]);
    setUseComputationTime(false);
  };

  const endTimeout = () => {
    dispatch({ type: MESSAGE_TYPE_END_TIMEOUT });
  };

  if (!match) return null;

  const handleTeamDeliberationEnd = () => {
    sendMessage({ matchID, type: MESSAGE_TYPE_TEAM_END_DELIBERATION });
  };

  const handleBonusDeliberationEnd = () => {
    setState({
      bonusDeliberationComplete: true,
      bonusDeliberationTimerStarted: false,
      bonusDeliberationTimerState: TIMER_STATE_PAUSED,
      persistBonusDeliberationTimerVisible: false,
    });
  };

  const handleBonusAnswerTimerEnd = () => {
    setState({
      bonusAnswerTimerStarted: false,
      persistBonusComputationTimerHidden: false,
      bonusAnswerTimerComplete: true,
      bonusAnswerTimerState: TIMER_STATE_PAUSED,
    });
  };

  const handleTossUpAnswerTimerEnd = () => {
    setState({
      tossUpAnswerTimerStarted: false,
      tossUpAnswerTimerComplete: true,
      tossUpAnswerTimerState: TIMER_STATE_PAUSED,
    });
  };

  const handleEndMatch = async () => {
    setEndMatch(true);
    const updatedMatch = await updateMatch({
      matchID,
      matchDate,
      matchName,
      propsToUpdate: { matchStatus: MATCH_COMPLETED },
    });
    if (updatedMatch) {
      playerList.forEach((player) => {
        if (player.active) {
          updateMatchPlayerWhenJoinOrLeave({
            playerID: player.playerID,
            active: 0,
          });
          // TODO: possibly causing memory leak trying to update state after navigating
          sendMessage({
            matchID: matchID,
            type: MESSAGE_TYPE_LEAVE_ROOM,
            // set skipAPIUpdate to true to prevent duplicate API updates
            payload: { skipAPIUpdate: true, playerID: player.playerID },
          });
        }
      });
      leaveRoom({ matchID, isHost: true, skipMessage: true });
      history.push('/host');
      return;
    }
    setEndMatch(false);
    setShowModalError(true);
  };

  let shouldPlayRingInSound;
  if (soundRingInTimerEnabled) {
    shouldPlayRingInSound = checkTruthy(soundRingInTimerEnabled);
  }

  let shouldPlayTossUpAnswerSound;
  if (soundTossUpAnswerEnabled) {
    shouldPlayTossUpAnswerSound = checkTruthy(soundTossUpAnswerEnabled);
  }

  let shouldPlayBonusDeliberationSound;
  if (soundBonusDeliberationTimerEnabled) {
    shouldPlayBonusDeliberationSound = checkTruthy(
      soundBonusDeliberationTimerEnabled,
    );
  }

  let shouldPlayBonusAnswerSound;
  if (soundBonusAnswerTimerEnabled) {
    shouldPlayBonusAnswerSound = checkTruthy(soundBonusAnswerTimerEnabled);
  }

  let shouldPlayTeamDeliberationSound;
  if (soundTeamDeliberationTimerEnabled) {
    shouldPlayTeamDeliberationSound = checkTruthy(
      soundTeamDeliberationTimerEnabled,
    );
  }
  // save score record to track question progress on timer end
  let onRingInTimerEnd = async () => {
    const allPlayers = getOtherPlayersString(playerList);
    await createScore({
      currentQuestion: activeQuestionNumber,
      matchID,
      otherPlayers: allPlayers,
      playerID: 0,
      points: 0,
      teamID: 0,
      type: 'playerTossUp',
    });
    endQuestion();
  };
  if (showPointButtons) {
    shouldPlayRingInSound = false;
    onRingInTimerEnd = () => {}; // do nothing if ring in timer ends while player answering question
  }
  const onRoundTimerEnd = () =>
    dispatch({ type: MESSAGE_TYPE_ROUND_TIMER_ENDED });

  let rightSideTimerDisplay = <div />;

  if (
    matchState === MATCH_STATE_RING_IN_TEST_OPEN ||
    matchState === MATCH_STATE_RING_IN_TEST_CLOSED
  ) {
    rightSideTimerDisplay = <div />;
  } else if (matchState === MATCH_STATE_TIMEOUT_OPEN) {
    rightSideTimerDisplay = (
      <div>
        <Timer
          duration={timeoutDuration}
          enabled
          resetDuration={timeoutDuration}
          label="Timeout"
          matchID={matchID}
          onEnd={endTimeout}
          timerState={timeoutTimerState}
          type={TIMER_TYPE_TIMEOUT}
          matchState={matchState}
          shouldPlaySound={checkTruthy(soundTimeoutTimerEnabled)}
          sound={roundTimeUpSound}
        />
      </div>
    );
  } else {
    switch (questionType) {
      case TOSS_UP_QUESTION: {
        rightSideTimerDisplay = (
          <div>
            <div>
              <Timer
                duration={ringInTimerDuration}
                enabled
                label="Ring-in Timer"
                matchID={matchID}
                onEnd={onRingInTimerEnd}
                resetDuration={ringInTimerResetDurationSec}
                shouldPlaySound={shouldPlayRingInSound}
                sound={ringInTimeUpSound}
                timerState={ringInTimerState}
                type={TIMER_TYPE_RING_IN}
                matchState={matchState}
                preserveMinTimeEnabled={checkTruthy(preserveMinTimeEnabled)}
                preserveMinTimeSeconds={preserveMinTimeSeconds}
              />
            </div>
            <div>
              <Timer
                duration={tossUpAnswerSeconds || 5}
                enabled={checkTruthy(tossUpAnswerEnabled)}
                label="Answer Timer"
                matchID={matchID}
                onEnd={handleTossUpAnswerTimerEnd}
                shouldPlaySound={shouldPlayTossUpAnswerSound}
                sound={answerTimeUpSound}
                timerState={tossUpAnswerTimerState}
                type={TIMER_TYPE_TOSS_UP_ANSWER}
                matchState={matchState}
              />
            </div>
          </div>
        );
        break;
      }
      case TEAM_QUESTION: {
        rightSideTimerDisplay = (
          <div>
            <Timer
              duration={teamDeliberationDuration}
              enabled
              label="Deliberation Timer"
              matchID={matchID}
              onEnd={handleTeamDeliberationEnd}
              shouldPlaySound={shouldPlayTeamDeliberationSound}
              sound={deliberationTimeUpSound}
              timerState={teamDeliberationTimerState}
              type={TIMER_TYPE_TEAM_DELIBERATION}
              matchState={matchState}
            />
          </div>
        );
        break;
      }
      // TODO: Refactor this show/hide state to use the enabled prop vs. timerState specifically. Also add to client room
      case BONUS_QUESTION: {
        rightSideTimerDisplay =
          checkTruthy(bonusDeliberationTimerEnabled) ||
          checkTruthy(bonusAnswerTimerEnabled) ? (
            <div>
              <div className="bonus-deliberation-timer">
                <Timer
                  duration={bonusDeliberationSeconds}
                  enabled
                  label="Deliberation Timer"
                  matchID={matchID}
                  onEnd={handleBonusDeliberationEnd}
                  shouldPlaySound={shouldPlayBonusDeliberationSound}
                  sound={deliberationTimeUpSound}
                  timerState={bonusDeliberationTimerState}
                  type={TIMER_TYPE_BONUS_DELIBERATION}
                  matchState={matchState}
                />
              </div>
              <div>
                <Timer
                  duration={bonusAnswerTimerDuration}
                  enabled
                  label="Answer Timer"
                  matchID={matchID}
                  onEnd={handleBonusAnswerTimerEnd}
                  shouldPlaySound={shouldPlayBonusAnswerSound}
                  sound={answerTimeUpSound}
                  timerState={bonusAnswerTimerState}
                  type={TIMER_TYPE_BONUS_ANSWER}
                  matchState={matchState}
                />
              </div>
            </div>
          ) : (
            <div />
          );
        break;
      }
      default: {
        break;
      }
    }
  }

  // if the match is already completed, hide most buttons
  if (currentMatchStatus === MATCH_COMPLETED) {
    questionText = 'MATCH COMPLETE';
    rightSideTimerDisplay = <div />;
  }

  const shouldRenderRoundTimer =
    roundTimerEnabled &&
    currentMatchStatus &&
    currentMatchStatus !== MATCH_COMPLETED;

  const roundTimer = (
    <Timer
      duration={roundTimerDurationSec}
      durationStored={storedRoundTimerDurationSec}
      enabled={roundTimerEnabled}
      label="Round Timer"
      matchID={matchID}
      onEnd={onRoundTimerEnd}
      shouldPlaySound={checkTruthy(soundRoundTimerEnabled)}
      sound={roundTimeUpSound}
      timerState={roundTimerState}
      type={TIMER_TYPE_ROUND}
      setRoundTimerExpired={setRoundTimerExpired}
    />
  );

  const content = (
    <TeamLayout
      numberOfTeams={match.teams.items.length}
      errorMessage={
        didJoinRoom && !isConnected ? (
          <div className="connection-error">
            <p>Connection lost! Waiting for new connection...</p>
            <p>
              If problem persists, check your status{' '}
              <a href="/connection-test" target="_blank">
                here
              </a>
            </p>
          </div>
        ) : null
      }
      roundTimer={shouldRenderRoundTimer ? roundTimer : <div></div>}
      questionText={<div className="question-type-text">{questionText}</div>}
      rightSideTimerDisplay={rightSideTimerDisplay}
      scoreboard={
        <Scoreboard
          className="host-room-scoreboard"
          match={match}
          matchState={matchState}
          scoreList={scoreList}
          startingPoints={startingPoints}
          teamsThatDidRingIn={teamsThatDidRingIn}
          isHost
          teamIdWithBonusControl={bonusTeamControlID}
        />
      }
      currentQuestion={
        <div className="current-question-container">
          <p>Current Question: {currentQuestion}</p>
        </div>
      }
      matchControls={
        <MatchControls
          endMatch={endMatch}
          endQuestion={endQuestion}
          endTimeout={endTimeout}
          handleBonusDeliberationEnd={handleBonusDeliberationEnd}
          handleEndMatch={handleEndMatch}
          handleTeamDeliberationEnd={handleTeamDeliberationEnd}
          isReplayingQuestion={isReplayingQuestion}
          match={match}
          matchID={matchID}
          nextQuestionNumberToPlay={nextQuestionNumberToPlay}
          playerRangIn={playerRangIn}
          questionNumberInputDisplay={questionNumberInputDisplay}
          replayQuestionNumber={replayQuestionNumber}
          scoreList={scoreList}
          setIsReplayingQuestion={setIsReplayingQuestion}
          setNextQuestionNumberToPlay={setNextQuestionNumberToPlay}
          setPlayerRangIn={setPlayerRangIn}
          setQuestionNumberInputDisplay={setQuestionNumberInputDisplay}
          setReplayQuestionNumber={setReplayQuestionNumber}
          setRingIns={setRingIns}
          setShowModalError={setShowModalError}
          setState={setState}
          setTeamDeliberationDuration={setTeamDeliberationDuration}
          setUseComputationTime={setUseComputationTime}
          showModalError={showModalError}
          teamDeliberationDuration={teamDeliberationDuration}
          roundTimerExpired={roundTimerExpired}
        />
      }
    />
  );

  return (
    <div className={`host-room ${className}`}>
      <HeaderAuthed isHost roomID={matchID} />
      {loadingComplete ? content : <LoaderCentered />}
    </div>
  );
};

function TeamLayout({
  numberOfTeams,
  errorMessage,
  roundTimer,
  questionText,
  rightSideTimerDisplay,
  scoreboard,
  currentQuestion,
  matchControls,
}) {
  if (numberOfTeams < 3) {
    return (
      <div className="content-container">
        <div className="left-column">{roundTimer}</div>
        <div className="center-column">
          {errorMessage}
          {questionText}
          {scoreboard}
          {currentQuestion}
          {matchControls}
        </div>
        <div className="right-column">{rightSideTimerDisplay}</div>
      </div>
    );
  } else {
    return (
      <div className="content-container">
        <div className="host-room-error-msg">{errorMessage}</div>
        <div className="host-room-question-text">{questionText}</div>
        {scoreboard}
        {currentQuestion}
        {roundTimer}
        {matchControls}
        {rightSideTimerDisplay}
      </div>
    );
  }
}

const StyledHostRoom = styled(HostRoom)`
  font-size: 2rem;
  line-height: 1.75rem;
  margin: 0 auto;
  text-align: center;

  .connection-error {
    color: red;
    line-height: 2.5rem;

    a {
      color: red;
      font-weight: bold;
    }
  }

  .content-container {
    display: grid;
    grid-template-columns: 1fr 60% 1fr;
    margin: 0px 20px;
  }

  .content-container-flex {
    display: flex;
  }

  .host-room-scoreboard {
    grid-column: 1 / 4;
    width: 100%;
  }

  .host-room-error-msg {
    grid-column: 1 / 4;
  }

  .host-room-question-text {
    grid-column: 1 / 4;
  }

  .current-question-container {
    grid-column: 1 / 4;
  }

  .margin-top {
    margin-top: 5rem;
  }

  .timer-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 12rem;
  }

  .controls-container {
    display: flex;
  }

  .button-container {
    position: relative;
    width: 95%;
    max-width: 62rem;
    margin: 0 auto;

    .divider {
      width: 100%;
      border-bottom: 3px solid #555;
      margin: 0.4rem;
    }
  }

  .button-container-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    min-height: 48px;
  }

  .center {
    justify-content: center;
    align-items: baseline;
  }

  .right {
    justify-content: end;
  }

  .computation-spacer {
    width: 12rem;
  }

  .end-deliberation-spacer {
    width: 12.1rem;
  }

  .bonus-deliberation-timer {
    margin-bottom: 1rem;
  }

  .question-label {
    height: 3.8rem;
    display: flex;
    align-items: baseline;
    margin: 0.5rem;
  }

  .question-type-select {
    margin-right: 1rem;
    font-weight: bold;
    height: 3.8rem;
  }

  .bottom {
    display: inline-block;
  }

  .question-container {
    margin-bottom: 1rem;
  }

  .question-type-text {
    min-height: 25px;
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    justify-content: start;
    font-size: 1.5rem;
  }

  .round-button {
    font-size: 1.25rem;
    margin: 0.5rem;
    text-transform: uppercase;
    padding: 0 10px;
    min-height: 3.8rem;
    max-width: 23rem;
    white-space: normal;
    overflow-wrap: break-word;
    line-height: 1.4rem;

    &:hover {
      text-decoration: underline;
    }

    &.stop {
      margin-left: auto;
    }

    &.power {
      background-color: blue;
      color: white;
    }

    &.positive {
      background-color: green;
      color: white;
    }

    &.negative {
      background-color: red;
      color: white;
    }

    &.neutral {
      background-color: yellow;
      color: black;
    }

    &.other {
      background-color: orange;
      color: black;
    }
  }

  @media (min-width: 900px) {
    .timer-container {
      width: 97.5%;
      margin: 0 2rem;
      display: flex;
      justify-content: space-between;
    }

    .button-container {
      width: 62rem;
    }

    .bottom {
      display: flex;
    }

    .question-type-text {
      font-size: 2rem;
    }
  }

  @media (max-width: 900px) {
    .content-container {
      display: block;
    }
  }

  @media (max-width: 900px) {
    .more-than-two-teams {
      display: block;
    }
  }
`;

export default StyledHostRoom;
